import bowser from 'bowser';

const isBrowserSupported = !bowser.isUnsupportedBrowser({
  msie: '11',
  firefox: '45',
  chrome: '40',
  safari: '10',
  ios: '10',
  opera: '50'
});

if (bowser.osname === 'iOS' && bowser.osversion && bowser.compareVersions([bowser.osversion,  '10']) < 0) {
  document.body.className += ' pi-outdated-browser';
  document.getElementById('pi-outdated-browser').innerHTML = 'Your os version is not supported';
} else if (!isBrowserSupported) {
  document.body.className += ' pi-outdated-browser';
}

export default isBrowserSupported;
