import Raven from 'raven-js';

if (process.env.REACT_APP_SENTRY_URL) {
  Raven.config(process.env.REACT_APP_SENTRY_URL, {
    environment: process.env.REACT_APP_SENTRY_ENV,
    serverName: process.env.REACT_APP_SENTRY_NAME,
    ignoreErrors: [
      'Script error',
      'Network error'
    ],
    whitelistUrls: [new RegExp(process.env.REACT_APP_SENTRY_WHITELIST)]
  }).install();

  if (window) {
    window.__Raven = Raven;
  }
}
